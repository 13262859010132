/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import defaultAxios from "../axios/Axios";

import { GET_SETTINGS, SET_INTAKES, SET_PROGRAMS, SET_DEPARTMENTS, SET_FORM_INTAKES } from "./types";
import { setAlerts } from "./alerts";

// Action to get the department settings
export const getSettings = (department) => async (dispatch) => {
  try {
    // Send the request to get settings
    const res = await defaultAxios.get(`/departments/${department}`);

    // Dispatch the result
    dispatch({
      type: GET_SETTINGS,
      payload: res.data,
    });
  } catch (error) {
    // Dispatch alert
    // const errors = error.response.data.errors;
    // if (errors)
    //     errors.forEach(error => dispatch(setAlerts(error.msg, "danger")));
  }
};

export const getIntakes = () => async (dispatch) => {
  try {
    const intakes = await defaultAxios.get("/intakes");

    dispatch({ type: SET_INTAKES, data: { intakes: intakes.data.intakes } });
  } catch (err) {
    alert("Error Occured while fetching the intakes");
  }
};

export const getFormIntakes = () => async (dispatch) => {
  try {
    const intakes = await defaultAxios.get("/intakes/form");
    dispatch({ type: SET_FORM_INTAKES, data: { formIntakes: intakes.data.intakename } });
  } catch (err) {
    alert("Error Occured while fetching the form intakes");
  }
};

export const getPrograms = () => async (dispatch) => {
  try {
    const programs = await defaultAxios.get("/programs");

    dispatch({ type: SET_PROGRAMS, data: { programs: programs.data.programs } });
  } catch (err) {
    alert("Error Occured while fetching the programs.");
  }
};

export const getDepartments = () => async (dispatch) => {
  try {
    const departments = await defaultAxios.get("/departments");

    dispatch({ type: SET_DEPARTMENTS, data: { departments: departments.data.departments } });
  } catch (err) {
    alert("Error Occurred while fetching the departments !");
  }
};

export const changeAutoSubmitToEnrol = (status) => async (dispatch) => {
  try {
    const res = await defaultAxios.get(`/departments/changeAutoSubmitToEnrol/${status}`);


  } catch (err) {
    alert("Error Occurred while changing auto submit to enrol status !");
  }

}

