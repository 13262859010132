/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import { CREATE_SAFETY_CONCERN_SUCCESS, GET_SAFETY_CONCERN, SAFETY_CONCERN_ERROR, SAFETY_CONCERN_LOADING, SET_SAFETY_CONCERN, UPDATE_SAFETY_CONCERN } from "../actions/types";

const initialState = {
    safetyConcernData: [],
    singleSafetyConcern: {},
    loading: false,
    error: null,
    currentPage: 1,
    totalPage: 1
};

export default (state=initialState, action ) => {
    // Get the type and payload
    const {type, payload} = action;

    switch(type) {
        case CREATE_SAFETY_CONCERN_SUCCESS:
            return {
                ...state,
                safetyConcernData: [...state.safetyConcernData, payload],
                loading: false
            };
        case GET_SAFETY_CONCERN:
            return {
                    ...state,
                    safetyConcernData: payload.safetyConcernData,
                    currentPage: payload.currentPage,
                    totalPage: payload.totalPage,
                    loading: false
                };
        case SAFETY_CONCERN_ERROR:
            return {...state, error: payload, loading: false};
        case SAFETY_CONCERN_LOADING:
            return {...state, loading: true};
            // for single safety concern
        case SET_SAFETY_CONCERN:
            return {...state, singleSafetyConcern: payload, loading: false};
        case UPDATE_SAFETY_CONCERN:
            return {...state, singleSafetyConcern: payload, loading: false};
        default:
            return state;
    }
}


