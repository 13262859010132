/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PrivateView from "../HOC/PrivateView/PrivateView";
import { useLocation } from 'react-router-dom';
import { Sidenav, Navbar, Nav, Panel, Avatar, Dropdown , Button, IconButton, Icon, Animation} from "rsuite";
// Redux
import { logout } from "../../actions/auth";

const NavBar = ({ logout, user, navigationData, history, props }) => {
    const { Fade, Collapse, Transition,Slide } = Animation;
    // get the user
    let name = "";

    if (user) name = user.firstName[0] + " " + user.lastName[0];

    const [toggle, setToggle] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setToggle(false);
        //* when location change, that means user clicked to a new page, then the menu should be hidden by default
    }, [location]);
    return (
        <>
        <Panel style={{ overflow: "visible" }}>
            <div className="mobile-nav">
                 <a href="/" className="navbar-brand logo">
                    <img width="140" src="/img/logo.png" />
                </a>
                 <IconButton
                    style={{ float: "right" }}
                        onClick={() => {
                            setToggle(!toggle);
                            document.body.classList.toggle('no-scroll');
                        }}
                    size="lg"
                    appearance="secondary"
                    icon={<Icon icon="align-justify" />}
                />
            </div>

            <Slide in={toggle} placement="right" className="mobile-sidenav" unmountOnExit={true}>
                <Sidenav appearance="subtle">
                    <div className="mobile-nav" style={{padding:"20px"}}>
                        <a href="/" className="navbar-brand logo">
                            <img width="140" src="/img/logo.png" />
                        </a>
                        <IconButton
                            style={{ float: "right" }}
                                onClick={() => {
                                    setToggle(!toggle);
                                    document.body.classList.toggle('no-scroll');
                                }}
                            size="lg"
                            appearance="secondary"
                            icon={<Icon icon="align-justify" />}
                        />
                    </div>
                    <Sidenav.Body>
                        <Nav style={{height:"100vh",overflowY:"scroll"}}>
                            {navigationData.map((navItem, index) => {
                            return (
                                <PrivateView key={navItem.key} accessService={navItem.permission}>
                                    {!!navItem.dropdown ? (
                                        <Dropdown title={navItem.label} eventKey={index}>
                                        {navItem.dropdown.map((item,index) => (
                                            <PrivateView key={item.key} accessService={item.permission}>
                                                <Dropdown.Item style={{ paddingTop: "0px"}} href={item.path}>
                                                    {item.label}
                                                </Dropdown.Item>
                                            </PrivateView>
                                        ))}
                                        </Dropdown>
                                    ) : (
                                        <Link from="/" to={navItem.path}>
                                        {" "}<Nav.Item eventKey={index}>{navItem.label}</Nav.Item>
                                        </Link>
                                    )}
                                </PrivateView>
                            );
                            })}
                            <Dropdown
                            placement="bottomEnd"
                            title={
                                <div style={{ marginTop: "-5px" }}>
                                {" "}
                                <span style={{ marginRight: "5px" }}>{name}</span>{" "}
                                <Avatar size="sm" circle>
                                    {name[0]}
                                </Avatar>
                                </div>
                            }
                            >
                                {user.permission === 1 ? (
                                    <Dropdown.Item style={{ width: "120px" }} onSelect={() => history.push(`/profile/edit/${user._id[0]}`)}>
                                    {" "}
                                    Profile
                                    </Dropdown.Item>
                                ) : (
                                    ""
                                )}
                                <Dropdown.Item style={{ width: "120px" }} onSelect={() => logout(user)} eventKey="4">
                                    Logout
                                </Dropdown.Item>
                            </Dropdown>
                        </Nav>
                    </Sidenav.Body>
                </Sidenav>
            </Slide>
        </Panel>
        </>
    );
};

NavBar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProp = (state) => ({
  user: state.auth.user,
  navigationData: state.settings.navigationData,
});

export default withRouter(connect(mapStateToProp, { logout })(NavBar));
