/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import { SET_ALERT, REMOVE_ALERT } from "./types";
import uuid from "uuid";

export const setAlerts =
  (msg, alertType, killAlert = 5000) =>
  (dispatch) => {
    // Gen a random id
    const id = uuid.v4();

    // Dispatch
    dispatch({
      type: SET_ALERT,
      payload: { msg, alertType, id, killAlert },
    });
  };
