/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import defaultAxios from "../axios/Axios";
import jwtDecode from "jwt-decode";
import { setAlerts } from "./alerts";
// alert compoenent
import { toaster } from "evergreen-ui";
import config from "../config.js";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOAD_USER,
  AUTH_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT,
  TOKEN_EXTEND_OPEN,
  TOKEN_EXTEND_CLOSE,
} from "./types";
import { msalClient, msalRequest } from "../authConfig";
let timeout;
let extendedTimeout;
// Action to logout user
export const logout = (user) => async (dispatch) => {
  // Dispatch
  if (config.environment === "prod") {
    let account = sessionStorage.getItem('msalAccount');
    const currentAccount = msalClient.getAccountByUsername(account);
    // The account's ID Token must contain the login_hint optional claim to avoid the account picker
    if (user.permission == 0 || user.permission == 2) {
      //const logoutHint = currentAccount.idTokenClaims.login_hint;
      //console.log(logoutHint);
      //await msalClient.logoutPopup({ logoutHint: logoutHint });
      await msalClient.logoutPopup({ account: currentAccount });
    }
  }
  dispatch({
    type: LOGOUT,
  });
  // clear the 15 seconds / 2 seconds timeout
  clearTimeout(extendedTimeout);
  clearTimeout(timeout);
  // Get the user
  dispatch(loadUser());
};

// Action to authenticate the user
export const loadUser = () => async (dispatch) => {
  //todo :  Get the token and check its about to expire ---
  let token = localStorage.getItem("token");

  if (token && token.length > 0) {
    let decodedToken = jwtDecode(token);
    let currentTime = (new Date().getTime() + 1) / 1000;
    let timeLeft = decodedToken.exp - currentTime;

    // If session expires
    timeout = setTimeout(function () {
      toaster.danger("Your session has been expired :( , Please Login again !", { duration: 2 });
      dispatch(logout());
    }, (Math.round(timeLeft) - 2) * 1000);

    // If 15 seconds left to expire show popup---
    extendedTimeout = setTimeout(function () {
      dispatch({ type: TOKEN_EXTEND_OPEN });
    }, (Math.round(timeLeft) - 17) * 1000);
  }

  // get the  current open intake --
  let currentIntake = await defaultAxios.get("register/registration/intake");

  // Send request to backend
  try {
    const res = await defaultAxios.get("/auth");

    let intakeCheck = { data: false };
    if (res.data.permission == 3) {
      intakeCheck = await defaultAxios.get("register/registration/check");
    }

    dispatch({
      type: LOAD_USER,
      payload: {
        res: res.data,
        intake: intakeCheck.data,

        currentIntake: currentIntake.data,
        currentRegistrationIntake: currentIntake.data.registrationTermName,
      },
    });
  } catch (err) {
    dispatch({
      type: AUTH_FAIL,
      payload: {
        currentIntake: currentIntake.data.name,
        currentRegistrationIntake: currentIntake.data.registrationTermName,
      },
    });
  }
};

// Action to register a user in database
export const register = (studentID, passport) => async (dispatch) => {
  // Set the headers
  const config = {
    headers: {
      "content-type": "application/json",
    },
  };

  // Encode data as json
  const data = JSON.stringify({ studentID, passport });

  // Send the axios request
  try {
    const res = await defaultAxios.post("/auth/register", data, config);
    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });
    // Load the user once registered
    dispatch(loadUser());
  } catch (err) {
    // Remove the token
    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

//Action to staff login
export const staffLogin = (username,useremail) => async (dispatch) => {
  try {
     const res = await defaultAxios.post("/auth/staffLogin", { username, useremail });
      console.log(res.data);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      // Get the user
      dispatch(loadUser());
  } catch (err) {
      console.log(err);
      dispatch({
        type: LOGIN_FAILED,
      });
      // Get the user
      //dispatch(loadUser());
      dispatch(logout());
  }

}

// Action to login user
export const login =
  (username, password, staffLogin = false) =>
  async (dispatch) => {
    // Send the request
    try {
      // Axios Request
      const res = await defaultAxios.post("/auth/login", { username, password, staffLogin });
      console.log(res.data);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });

      // Get the user
      dispatch(loadUser());
    } catch (err) {
      // Login failed
      dispatch({
        type: LOGIN_FAILED,
      });

      // Get the user
      dispatch(loadUser());
    }
  };

// action to regenerate a token when , it is about to expire
export const regenerateToken = (user) => async (dispatch) => {
  try {
    // close the modal first
    console.log("regenerate token");
    console.log(user);
    dispatch(tokenExtendClose());
    // 👀 the parameters can be taken out from the former jwt token ...
    const res = await defaultAxios.post("/auth/regenerateToken");

    if (user.stafflogin !== undefined) {
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
    }

    clearInterval(timeout);
    // Get the user
    dispatch(loadUser());
  } catch (err) {
    // Login failed
    if (user.stafflogin !== undefined) {
      dispatch({
        type: REGISTER_FAIL,
      });
    } else {
      dispatch({
        type: LOGIN_FAILED,
      });
    }

    // Get the user
    dispatch(loadUser());
  }
};

// control state of extend token modal from here
export const tokenExtendOpen = () => async (dispatch) => {
  dispatch({
    type: TOKEN_EXTEND_OPEN,
  });
};

// close the regeneration token confirmation modal
export const tokenExtendClose = () => async (dispatch) => {
  dispatch({
    type: TOKEN_EXTEND_CLOSE,
  });
};
