/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import { SET_ALERT, REMOVE_ALERT } from "../actions/types.js";
const initialState = [];

export default (state = initialState, action) => {
  // Get the type and payload
  const { type, payload } = action;
  switch (type) {
    case SET_ALERT:
      return [payload];
    case REMOVE_ALERT:
    // In case someone decides to use the alert component instead of toaster
    //return state.filter(alert => alert.id !== payload);
    default:
      return state;
  }
};
