/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import { SET_INTAKES, SET_PROGRAMS, SET_DEPARTMENTS, SET_FORM_INTAKES } from "../actions/types";

const initialState = {
  loading: true,
  intakes: null,
  programs: null,
  departments: null,
  formIntakes: "",
  documentsData: [
    {
      department: "Orientation",
      documents: [
        "Immigration Document",
        "passport",
        "feeReceipt",
        "LOA",
        "preArrival",
        "AIP",
        "Study Permit",
        "Study Permit Approval letter",
        "Study Permit Application Screenshot",
        "TRV Stamp or Sticker",
      ],
    },
    { department: "Retakes", documents: ["fee Reciept"] },
    {
      department: "Quarantine",
      documents: ["Quarantine Agreement", "TRV Stamp or Sticker", "Fee Receipt", "POC", "Hotel Reservation Form", "OQCD", "Flight Ticket"],
    },
    { department: "Schedules", documents: [] },
  ],
  navigationData: [
    // {
    //   key: "HelpDesk",
    //   label: "Help Desk",
    //   path: "/helpdesk",
    //   permission: "superOnly"
    // },
    // {
    //   key: "HelpDesk",
    //   label: "Help Desk",
    //   path: "/form/helpdesk",
    //   permission: "approvedStudents"
    // },
    {
      key: "Safety Concern",
      label: "Safety Concern",
      path: "/safetyconcern",
      permission: "safetyConcerns",
    },
    {
      key: "HelpDesk",
      label: "Student Complaints",
      path: "/helpdesk",
      permission: "studentComplaints",
    },
    {
      key: "Convocation",
      label: "Convocation",
      path: "/convocation",
      permission: "superOnly"
    },

    {
      key: "Convocation",
      label: "Convocation ",
      path: "/status/convocation",
      permission: "approvedAndGraduatedStudents"
    },

    {
      key: "Immigration Documents",
      label: "Immigration Documents",
      path: "/immigration",
      permission: "approvedStudents",
    },

    {
      key: "view profiles",
      label: "Student Profiles",
      path: "/profile/",
      permission: "profileReadOnly",
    },

    {
      key: "massemaillist",
      label: "Mass Emails",
      permission: "massEmails",
      path: "/massemails/batchlist",
      dropdown: [
        {
          key: "studentlist",
          label: "Student List ",
          path: "/massemails/list",
          permission: "massEmails",
        },
        {
          key: "batch list",
          label: "Sent Box",
          path: "/massemails/batchlist",
          permission: "massEmails",
        },
      ],
    },

    {
      key: "asasa",
      label: "Registration",
      permission: "orientationNdGraduationNdRegistrationStd",
      path: "/orientation",
      dropdown: [
        {
          key: "asasdsa",
          label: "Registrations",
          path: "/orientation",
          permission: "orientationNdRegistrationStd",
        },
        {
          key: "avsrfratresa",
          label: "Add Students",
          path: "/createStudents",
          permission: "orientationAdmins",
        },
        {
          key: "avsrfrttt",
          label: "All Students",
          path: "/students/list",
          permission: "orientation",
        },
        {
          key: "Set Documents",
          label: "Set Forms",
          path: "/setForms",
          permission: "superOnly",
        }
      ],
    },

    // {
    //   key: "glist",
    //   label: "Graduation",
    //   path: "/graduation/list",
    //   permission: "graduation",
    // },


    {
      key: "glist",
      label: "Graduation",
      permission: "graduation",
      path: "/graduation/list",
      dropdown: [
        {
          key: "graduation",
          label: "Certificate Form ",
          path: "/graduation/list",
          permission: "graduation",
        },
        {
          key: "expedited list",
          label: "Expedited Form ",
          path: "/expedited/list",
          permission: "graduation",
        },
      ],
    },

    // one more dropdown in graduation section

    {
      key: "retakes",
      label: "Retakes",
      path: "/retake/",
      permission: "retakesNdApprovedStd",
    },
    {
      key: "schedules",
      label: "Schedules",
      path: "/groups/",
      permission: "GROUP_ADMIN",
      dropdown: [

        {
          key: "avsrfratresa",
          label: " View Groups ",
          path: "/groups/",
          permission: "GROUP_ADMIN",
        },
        {
          key: "viewgroups",
          label: " Student list ",
          path: "/selected/groups",
          permission: "GROUP_ADMIN",
        },
      ],
    },


    {
      key: "aferfsae",
      label: "Alumni List",
      path: "/aluminis/",
      permission: "approvedStudents",
    },

    {
      key: "aferfsa",
      label: "Schedule",
      path: "/schedules/",
      permission: "approvedStudents",
    },

    // ⛔️ Removing documents feature until needed

    {
      key: "Documents",
      label: "Documents",
      permission: "allAdminsNdSuper",
      path: "/documents/immigrationlist",
      dropdown: [
        {
          key: "docs",
          label: "Documents",
          path: "/documents/studentlist",
          permission: "allAdminsNdSuper",
        },
        {
          key: "immigration",
          label: "Immigration ",
          path: "/documents/immigrationlist",
          permission: "immigration",
        },
      ],
    },

    {
      key: "Graduation",
      label: "Graduation",
      permission: "approvedStudents",
      path: "/documents/graduation",
      dropdown: [
        {
          key: "graduation form",
          label: "Certificate Form ",
          path: "/documents/graduation",
          permission: "approvedStudents",
        },
        {
          key: "expedited graduation form",
          label: "Expedited Form ",
          path: "/expedited/form",
          permission: "approvedStudents",
        },
      ],
    },

    // {
    //   key: "Documents",
    //   label: "Documents",
    //   permission: "approvedStudents",
    //   dropdown: [
    //     {
    //       key: "docs",
    //       label: "Documents",
    //       path: "/documents/dashboard",
    //       permission: "approvedStudents",
    //     },
    //   ],
    // },
    /*
    {
      key: "settings",
      label: "Settings",
      permission: "superOnly",
      dropdown: [
        {
          key: "staff_settings",
          label: " Staff Settings ",
          path: "/usersSettings",
          permission: "superOnly",
        },
        {
          key: "intake_settings",
          label: " Intake Settings ",
          path: "/intakes",
          permission: "superOnly",
        },
      ],
    },*/
    {
      key: "coop",
      label: "Co-op",
      path: "/coop",
      permission: "coop"
    },
    // {
    //     key: 'quarantine',
    //     label: 'Quarantine',
    //     path: "/quarantine/redirector",
    //     permission: "QUARANTINE_ALL_PROFILE"
    // },
  ],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "GET_SETTINGS": {
      return {
        ...state,
        loading: false,
        [payload.name]: payload,
      };
    }

    case SET_PROGRAMS: {
      return {
        ...state,
        programs: action.data.programs,
      };
    }

    case SET_INTAKES: {
      return {
        ...state,
        intakes: action.data.intakes,
      };
    }
    case SET_FORM_INTAKES: {
      return {
        ...state,
        formIntakes: action.data.formIntakes,
      };
    }

    case SET_DEPARTMENTS: {
      return {
        ...state,
        departments: action.data.departments,
      };
    }

    default:
      return state;
  }
};
