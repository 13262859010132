/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import {GET_ORGANIZATION_OPTION,GET_EMPLOYER_OPTION,GET_COOP_DETAIL, GET_EMPLOYER_LOG } from "../actions/types";

const initialState = {
  orgOption: {},
  empOption: {},
  detail: {},
  log: {},
  type: {},
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ORGANIZATION_OPTION:
      return { ...state, orgOption: payload };
    case GET_EMPLOYER_OPTION:
      return { ...state, empOption: payload };
    case GET_COOP_DETAIL:
      return { ...state, detail: payload.data, type: payload.type };
    case GET_EMPLOYER_LOG:
      return { ...state, log: payload.data};
    default:
      return state;
  }
};
