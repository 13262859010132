/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import React from "react";
import { Link, withRouter } from "react-router-dom";

// Import the UI components
import { Button, Card, Text, Heading } from "evergreen-ui";

const Empty = ({ img, title, message, buttonLink, buttonValue,buttonredirect, imageSize = 200, link }) => {
  return (
    <Card className="empty-container" elevation={2} style={{paddingBottom: "25px"}} >
      <img width={imageSize} src={img} style={{ "margin": "30px 0px" }} />
      <div className="card-content" style={{ "marginBottom": "25px" }}>
        <Heading size={600} style={{ "marginBottom": "15px" }}>
          {title}
        </Heading>
        <Text>{message}</Text>
      </div>

      {link ? (
        <>
          <a href={link} style={{ "marginBottom": "50px" }}>
            <Button appearance="primary">{buttonValue}</Button>
          </a>
        </>
      ) : (
        <>
        {buttonValue == "" ?
            <></>
            :
            <a href={`mailto:${buttonLink}`} style={{ "marginBottom": "50px" }}>
              <Button appearance="primary">{buttonValue}</Button>
            </a>
          }
        </>
      )}
    </Card>
  );
};

export default Empty;
