/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import { CREATE_HELPDESK_SUCCESS, FILTER_HELPDESK, GET_HELPDESK, HELPDESK_ERROR, HELPDESK_LOADING, SET_HELPDESK, UPDATE_HELPDESK } from "../actions/types";

const initialState = {
    helpdeskData: [],
    singleHelpdesk: {},
    loading: false,
    error: null,
    currentPage: 1,
    totalPage: 1
};

export default (state=initialState, action ) => {
    // Get the type and payload
    const {type, payload} = action;

    switch(type) {
        case CREATE_HELPDESK_SUCCESS:
            return {
                ...state,
                helpdeskData: [...state.helpdeskData, payload],
                loading: false
            };
        case GET_HELPDESK:
            return {
                    ...state,
                    helpdeskData: payload.helpdeskData,
                    currentPage: payload.currentPage,
                    totalPage: payload.totalPage,
                    loading: false
                };
        case HELPDESK_ERROR:
            return {...state, error: payload, loading: false};
        case HELPDESK_LOADING:
            return {...state, loading: true};
        case SET_HELPDESK:
            return {...state, singleHelpdesk: payload, loading: false};
        case UPDATE_HELPDESK:
            return {...state, singleHelpdesk: payload, loading: false};
        default:
            return state;
    }
}
