
 /*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import mainAxios from "../axios/Axios";

 // function to view a file
 async function viewFile(fileInfo) {
    const report = await mainAxios.post(`/documents/download`, {  fileInfo }, { responseType: "blob" })
      .then(res => {
       const file = new Blob(
           [res.data],
           {type: res.data.type});

      //Build a URL from the file
       const fileURL = URL.createObjectURL(file);

       //Open the URL on new Window - urls just attached to the document
       window.open(fileURL);
       }).catch(err=>{
        // history.push("/retakes/list")
      })
  }


 export default viewFile;
