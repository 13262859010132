 const config = {
    name: "Lambton College In Mississauga",
    server: "https://services.queenscollege.ca/apiapi/",
    url: "http://services.queenscollege.ca",
    moodle: "https://moodle.queenscollege.ca",
    chatToken: "",
    forgetPassword: "https://enrol.queenscollege.ca/queensenrol/student/resetpassword",
    freshChat: "63e36bdd-f92c-4714-9ffa-51e6765c7243",
    theme: {
        primary: "primary",
    },
    environment:"prod"
}

export default config;
