/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import { combineReducers } from "redux";
import alerts from "./alerts";
import auth from "./auth";
import retakes from "./retakes";
import orientation from "./orientation";
import countries from "./countries";
import schedules from "./schedules";
import settings from "./settings";
import student from "./student";
import quarantine from "./quarantine";
import documents from "./documents";
import classes from "./classes";
import courses from "./courses";
import programs from "./programs"
import profiles from "./profile"
import instructor from "./instructor";
import coop from "./coop";
import helpdesk from "./helpdesk";
import safetyconcern from "./safetyconcern";

const allReducers = combineReducers({
    alerts,
    auth,
    retakes,
    orientation,
    countries,
    schedules,
    settings,
    quarantine,
    documents,
    classes,
    courses,
    student,
    programs,
    profiles,
    instructor,
    coop,
    helpdesk,
    safetyconcern
});

export const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }

  return allReducers(state, action);
};
