/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Icon, Button, SelectPicker, ControlLabel, FormGroup, FormControl, Form } from "rsuite";

import { getDepartments } from "../../../actions/settings.js";
import { sendNotificationToClass } from "../../../actions/documents";
//import { mapToInputPicker } from "../../../services/QuarantinePackage/Utils/tools"; //?not used
import { toaster } from "evergreen-ui";

const AlertClassModal = ({
  onModalClose,
  getDepartments,
  departments,
  staffDepartments,
  documentsData,
  classes,
  sendNotificationToClass,
}) => {
  const [state, setState] = useState({
    classId: "",
    department: "",
    documents: [""],
    other: "",
  });

  const [documentsArray, setDocumentsArray] = useState([]);

  useEffect(() => {
    getDepartments();

    const documents = documentsData.filter((item) => {
      return item.department === state.department;
    });
    const documentsArr = documents[0] ? documents[0].documents : [];
    setDocumentsArray(documentsArr);
  }, [state.department]);

  const onChange = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const onDocumentChange = (index, key, value) => {
    const newDocument = [...state.documents];
    newDocument[index] = { ...newDocument[index], [key]: value };
    setState({ ...state, documents: [...newDocument] });
  };

  //** */ if you change the selected department , the use Effect will run and
  //** */ make the selected document types  to empty array again

  const addField = () => {
    let newArr = [...state.documents];
    newArr.push("");
    setState({ ...state, documents: [...newArr] });
  };

  const deleteField = (index) => {
    let newArr = [...state.documents];
    newArr.splice(index, 1);
    setState({ ...state, documents: [...newArr] });
  };

  return (
    <div>
      <Form>
        <FormGroup>
          <ControlLabel> Class Name :</ControlLabel>
          <SelectPicker
            appearance="default"
            value={state.classId}
            onChange={(value) => onChange("classId", value)}
            style={{ width: "300px" }}
            data={
              classes &&
              Object.values(classes).map((item) => {
                return { label: item.name, value: item.name };
              })
            }
          />
        </FormGroup>

        <FormGroup>
          <ControlLabel> Select Department :</ControlLabel>
          <SelectPicker
            style={{ width: "300px" }}
            placeholder="Select Department"
            value={state.department}
            onChange={(value) => onChange("department", value)}
            data={
              staffDepartments.length == 0
                ? departments.map((item) => {
                    return { label: item.name, value: item.name };
                  })
                : staffDepartments.map((item) => {
                    return { label: item.name, value: item.name };
                  })
            }
          />
        </FormGroup>

        <FormGroup>
          <ControlLabel>
            {" "}
            Select Document Type :
            <Icon
              style={{ color: "darkblue", cursor: "pointer", fontSize: "1.2rem", position: "relative", top: "3px", left: "5px" }}
              icon={"plus-square"}
              onClick={() => addField()}
            ></Icon>
          </ControlLabel>
          {state.documents.map((item, index) => {
            return (
              <>
                <SelectPicker
                  style={{ width: 300, marginBottom: "5px" }}
                  placeholder="Select Document"
                  value={state.documents[index].document}
                  onChange={(value) => onDocumentChange(index, "document", value)}
                  data={documentsArray.map((item) => {
                    return { label: item, value: item };
                  })}
                />
                <Icon
                  onClick={() => deleteField(index)}
                  style={{ fontSize: "1.5rem", marginLeft: "10px", position: "relative", top: "3px", color: "darkred", cursor: "pointer" }}
                  icon={"minus-square"}
                ></Icon>
              </>
            );
          })}
        </FormGroup>

        {/* Submit button for testing the documents values choosen  */}
        <Button
          appearance="primary"
          style={{ position: "relative", top: "10px" }}
          onClick={async () => {
            // close the modal and send data to redux store and call api there.

            const status = await sendNotificationToClass(state);

            if (status === 200) {
              onModalClose("class", false);
              toaster.success("The request has been sent successfully !", { duration: 3 });
            }
          }}
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  departments: state.settings.departments,
  staffDepartments: state.auth.user.departments,
  documentsData: state.settings.documentsData,
  classes: state.classes,
});

export default connect(mapStateToProps, { getDepartments, sendNotificationToClass })(AlertClassModal);
