/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import defaultAxios from "../axios/Axios";
import {
  GET_DOCUMENTS,
  SET_DOCUMENTS_LOADING,
  PENDING_DOCUMENTS,
  GET_SELECTED_DOCUMENT,
  SUBMIT_ATTACHED_DOCUMENT,
  GET_STAFF_SELECTED_DOCUMENT,
  GET_IMMIGRATION_DOCUMENT_LIST,
  GET_SELECTED_IMMIGRATION_DOCUMENTS,
  GET_DOCUMENT_STATUS_LIST,
} from "./types";

import { toaster } from "evergreen-ui";

const sendNotificationToStudent = (data) => async (dispatch) => {
  try {
    const res = await defaultAxios.post("/documents/notifystudent", data);

    return res.status;
  } catch (err) {
    // toaster.danger("Error occured in sending notification !")
  }
};

const sendNotificationToClass = (data) => async (dispatch) => {
  try {
    const res = await defaultAxios.post("/documents/notifyclass", data);

    return res.status;
  } catch (err) {
    // toaster.danger("Error occured in sending notification !")
  }
};

const getStudentDocuments = (filter) => async (dispatch) => {
  try {
    setDocumentsLoading(true);

    let query = "";
    const staticFields = new Set(["page", "type", "department"]);

    Object.keys(filter).forEach((key) => {
      if (staticFields.has(key) && !!filter[key]) {
        query = query + `${key}=${filter[key]}&`;
      }
    });

    const res = await defaultAxios.post(`/documents/mylist?${query}`);

    return dispatch({
      type: GET_DOCUMENTS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

const getPendingDocuments = () => async (dispatch) => {
  try {
    const res = await defaultAxios.get("/documents/pendingdocuments");

    // setDocumentsLoading(false)

    return dispatch({
      type: PENDING_DOCUMENTS,
      payload: res.data,
    });
  } catch (err) {}
};

const getSelectedDocument = (data) => async (dispatch) => {
  try {
    const type = data.value;
    const department = data.role;

    const res = await defaultAxios.get(`/documents/pendingdocuments/${type}/${department}/get`);

    return dispatch({
      type: GET_SELECTED_DOCUMENT,
      payload: res.data,
    });
  } catch (err) {}
};

const getStaffSelectedDocument = (data) => async (dispatch) => {
  try {
    const { type, studentId, department } = data;

    const res = await defaultAxios.get(`/documents/${studentId}/${type}/${department}`);

    return dispatch({
      type: GET_STAFF_SELECTED_DOCUMENT,
      payload: res.data,
    });
  } catch (err) {}
};

const deleteSubmittedDocument = (data) => async (dispatch) => {
  try {
    const type = data.value;
    const department = data.role;
    //  delete doc from pending list when submittd and update the list
    // delete or put (we will decide)
    const res = await defaultAxios.delete(`/documents/pendingdocuments/${type}/${department}/update`);

    return res;
  } catch (err) {}
};

const submitAttachedDocument = (data) => async (dispatch) => {
  try {
    const res = await defaultAxios.put(`/documents/staff/pendingdocuments/update`, data);
    return res;
  } catch (err) {}
};

const setDocumentsLoading = (loading) => (dispatch) => {
  dispatch({
    type: SET_DOCUMENTS_LOADING,
    payload: loading,
  });
};

//** Immigration form actions --- ***

const submitImmigrationDocuments = (data) => async (dispatch) => {
  const res = await defaultAxios.post(`/profile/immigration/saveDocuments`, data);

  if (res.status == 200) {
    toaster.success("Forms saved successfully !");
  }

  return res;
};

const getImmigrationDocumentsList = (data = "") => async (dispatch) => {
  let res;
  if (data) {
     res = await defaultAxios.get(`/profile/immigration/documentlist/${data}`);
  }
  else {
     res = await defaultAxios.get(`/profile/immigration/documentlist`);
  }
  return dispatch({
    type: GET_IMMIGRATION_DOCUMENT_LIST,
    payload: res.data,
  });
};

// *** not used
const getSelectedImmigrationDocuments = (data) => async (dispatch) => {
  const res = await defaultAxios.get(`/profile/immigration/documents/`);

  return dispatch({
    type: GET_SELECTED_IMMIGRATION_DOCUMENTS,
    payload: res.data,
  });
};



const getDocumentStatusList = (data = "") => async (dispatch) => {
  let res;
  if (data) {
    res = await defaultAxios.get(`/profile/immigration/documentStatusList/${data}`);
  } else {
    res = await defaultAxios.get(`/profile/immigration/documentStatusList`);
  }

  return dispatch({
    type: GET_DOCUMENT_STATUS_LIST,
    payload: res.data,
  });
};

export {
  sendNotificationToStudent,
  sendNotificationToClass,
  setDocumentsLoading,
  getPendingDocuments,
  getSelectedDocument,
  deleteSubmittedDocument,
  submitAttachedDocument,
  getStaffSelectedDocument,
  getStudentDocuments,
  submitImmigrationDocuments,
  getImmigrationDocumentsList,
  getSelectedImmigrationDocuments,
  getDocumentStatusList,
};
