/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import React from "react";
import PageNotFoundImg from "../../../assets/images/fileNotFound.svg";

class PageNotFound extends React.PureComponent {
  render() {
    return (
      <div>
        <div style={{ textAlign: "center" }}>
          <h1 style={{ textAlign: "center" }}>Page Not Found!</h1>
          <img src={PageNotFoundImg} style={{ width: "50%", marginTop: "30px" }} alt="page not found" />
        </div>
      </div>
    );
  }
}

export default PageNotFound;
