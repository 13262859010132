/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import React from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";

// Import the UI components
import { toaster } from "evergreen-ui";

// Function to exec alert
const execAlert = ({ msg, alertType }) => {
  toaster[alertType](msg);
};

const Alerts = ({ alerts }) => {
  return alerts !== null && alerts.length > 0 && alerts.map((alert) => execAlert(alert));
};

Alerts.propTypes = {
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.alerts,
});

export default connect(mapStateToProps)(Alerts);
