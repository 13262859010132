/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOAD_USER,
  AUTH_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT,
  REGENERATE_TOKEN,
  TOKEN_EXTEND_OPEN,
  TOKEN_EXTEND_CLOSE,
} from "../actions/types";

import quarantinePermissions from "../services/QuarantinePackage/Utils/Permissions";
const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  loading: true,
  user: null,
  intake: "",
  currentIntake: {},
  currentRegistrationIntake: "",
  tokenExtend: false,
  accessServices: {
    superOnly: { accessLevel: [0], departments: {} },
    allAdminsNdSuper: { accessLevel: [0, 2], departments: { $all: new Set(["admin", "Member"]) } },
    privatePublic: { accessLevel: [0, 1, 2, 4], departments: { $all: new Set(["$all"]) } },
    approvedStudents: { accessLevel: [1], departments: {} },
    registrationStudents: { accessLevel: [3], departments: {} },
    privatePublicAll: { accessLevel: [0, 1, 2, 3, 4], departments: { $all: new Set(["$all"]) } },
    exceptApprovedStudents: { accessLevel: [0, 2, 3], departments: { $all: new Set(["$all"]) } },

    approvedAndGraduatedStudents: { accessLevel: [1, 4], departments: {} },
    graduatedStudents: { accessLevel: [4], departments: {} },

    orientation: { accessLevel: [0, 2], departments: { Orientation: new Set(["admin", "Member"]) } },
    orientationNdGraduationNdRegistrationStd: {
      accessLevel: [0, 2, 3],
      departments: { Orientation: new Set(["admin", "Member"]), Graduation: new Set(["admin", "Member"]) },
    },
    orientationNdRegistrationStd: { accessLevel: [0, 2, 3], departments: { Orientation: new Set(["admin", "Member"]) } },
    orientationAdmins: { accessLevel: [0, 2], departments: { Orientation: new Set(["admin"]) } },

    retakes: { accessLevel: [0, 2], departments: { Retakes: new Set(["Member", "admin"]) } },
    retakesNdApprovedStd: { accessLevel: [0, 2, 1], departments: { Retakes: new Set(["admin", "Member"]) } },

    orientationandRetakes: { accessLevel: [0, 2, 3], departments: { Orientation: new Set(["admin", "Member"]) } },

    //send mass emails
    massEmailsNdOrientation: { accessLevel: [0, 2], departments: { Orientation: new Set(["admin", "Member"]), MassEmails: new Set(["admin", "Member"]) }, },
    massEmails: { accessLevel: [0, 2], departments: { MassEmails: new Set(["Member", "admin"]) } },

    //immigration
    immigration: { accessLevel: [0, 2], departments: { Immigration: new Set(["admin", "Member"]) } },
    immigrationAdmin: { accessLevel: [0, 2], departments: { Immigration: new Set(["admin"]) } },
    //graduation
    graduation: { accessLevel: [0, 2], departments: { Graduation: new Set(["admin", "Member"]) } },
    //coop
    coop: { accessLevel: [0, 2], departments: { Coop: new Set(["admin", "Member"]) } },
    coopAdmins: { accessLevel: [0, 2], departments: { Coop: new Set(["admin"]) } },


    //student compakinst 

    studentComplaints: { accessLevel: [0, 1, 2, 4], departments: { StudentComplaints: new Set(["admin", "Member"]) } },
    //safety concern 
    safetyConcerns: { accessLevel: [0, 2], departments: { SafetyConcerns: new Set(["admin", "Member"]) } },


    profileReadOnly: { accessLevel: [0, 2], departments: { ReadOnly: new Set(["profileOnly"]), Orientation: new Set(["admin", "Member"]) } },

    allApproved: { accessLevel: [1, 0, 2], departments: {} },
    GROUP_ADMIN: { accessLevel: [0, 2], departments: { Schedules: new Set(["admin", "Member"]) } },
    GROUP_ALL: { accessLevel: [0, 2, 1], departments: { Schedules: new Set(["$all", "Member"]) } },
    ...quarantinePermissions,
  },
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_USER:
      return {
        ...state,
        user: payload.res,
        isAuthenticated: true,
        loading: false,
        intake: payload.intake,
        currentIntake: payload.currentIntake,
      };

    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      localStorage.setItem("token", payload.token);

      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
      };
    case REGISTER_FAIL:
    case AUTH_FAIL:
      localStorage.removeItem("token");

      return {
        ...state,
        ...payload,
        isAuthenticated: false,
        loading: false,
        // currentIntake : payload.currentIntake,
        // currentRegistrationIntake : payload.currentRegistrationIntake
      };

    case LOGOUT:
      localStorage.removeItem("token");
    case LOGIN_FAILED:
      localStorage.removeItem("token");
      return {
        accessServices: state.accessServices,
      };

    case TOKEN_EXTEND_CLOSE:
      return {
        ...state,
        ...payload,
        tokenExtend: false,
      };

    case TOKEN_EXTEND_OPEN:
      return {
        ...state,
        ...payload,
        tokenExtend: true,
      };

    default:
      return state;
  }
};
