/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import React from "react";
import Loading from "../../../components/layout/Loading/Loading";
import ReactPaginate from "react-paginate";
import { Table, Input,  Row, Col } from "rsuite";
import { Button } from "evergreen-ui";
import viewFile from "../../../utils/viewFile"

const { Column, HeaderCell, Cell } = Table;

const StudentList = ({ page, lists, history, onfilterChange, loading, filter, totalCount, intakes }) => {


  return (
    <>
      {intakes ? (
        <div>
          <Row style={{ marginTop: "1px", marginBottom: "10px" }} className="show-grid">
            <Col xs={24}>
              <Table
                loading={loading}
                height={500}
                style={{ marginTop: "1px" }}
                data={lists}
                bordered={true}
                cellBordered={true}
                hover={true}
              >
                <Column flexGrow={1} minWidth={100}>
                  <HeaderCell>
                    <Input
                      style={{ height: "30px", padding: "0px", border: "none" }}
                      placeholder="Student Id ..."
                      value={filter.studentId}
                      onChange={(value) => onfilterChange("studentId", value)}
                    />
                  </HeaderCell>
                  <Cell dataKey="studentId" />
                </Column>

                <Column flexGrow={1} minWidth={100}>
                  <HeaderCell>
                    <Input
                      style={{ height: "30px", padding: "0px", border: "none" }}
                      placeholder="Type..."
                      onChange={(value) => onfilterChange("type", value)}
                    />
                  </HeaderCell>
                  <Cell dataKey="type" />
                </Column>

                <Column flexGrow={1} minWidth={100}>
                  <HeaderCell>
                    <Input
                      style={{ height: "30px", padding: "0px", border: "none" }}
                      placeholder="Department..."
                      onChange={(value) => onfilterChange("department", value)}
                    />
                  </HeaderCell>
                  <Cell dataKey="department" />
                </Column>

                {/* <Column flexGrow={1} >
              <HeaderCell>

              <SelectPicker
                  appearance="default"
                  placeholder={"Select Intake"}
                  onChange={(value) => onfilterChange("intake", value)}
                  data={intakes}
                  style={{ height: "30px", width: "100%", padding: "0px", border: "none", marginTop: "0px" }}
                />

              </HeaderCell>
              <Cell style={{textAlign : "center"}} dataKey="intake" />
            </Column> */}

                <Column style={{ textAlign: "center" }} flexGrow={1}>
                  <HeaderCell>
                    <p
                      style={{
                        height: "30px",
                        padding: "0px",
                        border: "none",
                        textAlign: "center",
                        fontSize: "0.9rem",
                        marginTop: "3.6px",
                      }}
                    >
                      {" "}
                      Download{" "}
                    </p>
                  </HeaderCell>
                  <Cell style={{ textAlign: "center" }}>
                    {(rowData, rowIndex) => {
                      return (
                        <Button
                          appearance="primary"
                          style={{ cursor: "pointer", marginTop: "-4px" }}
                          onClick={() => {
                                viewFile(rowData.fileInfo || "");
                              }}

                        >
                          <a
                            style={{ color: "white" }}

                          >
                            Download
                          </a>
                        </Button>
                      );
                    }}
                  </Cell>
                </Column>
              </Table>

              {totalCount > 10 && (
                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={totalCount / 10}
                  initialPage={page}
                  disableInitialCallback={true}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={(e) => {
                    onfilterChange("page", e.selected);
                  }}
                  containerClassName={"pagination"}
                  activeLinkClassName={"active"}
                  previousClassName={"previous"}
                />
              )}
            </Col>
          </Row>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default StudentList;
