/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
// Import the UI component
import {  Row, Col, Grid, Button } from "rsuite";
import { getStudentDocuments } from "../../../actions/documents";
import ListComponent from "./submittedDocumentsList";

const StudentDashboard = ({ history, getStudentDocuments, list, totalCount }) => {
  const [filter, setFilter] = useState({
    department: "",
    type: "",
    page: 0,
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getStudentDocuments(filter);
  }, [filter.page, filter.department, filter.type]);

  const onfilterChange = (key, value) => {
    setFilter({ ...filter, [key]: value });
  };

  console.log(list)

  return (
    <div>
      <Grid fluid style={{ width: "80%", margin: "0px auto", marginTop: "20px" }} className="documents-grid">
        <Row className="show-grid">
          <Col xs={24} style={{ marginTop: "30px", marginBottom: "40px" }}>
            <h3 style={{ marginLeft: "10px", marginBottom: "20px", display: "inline-block", color: "#234361", fontSize: "1.9rem" }}>
              All Documents
            </h3>
            <Button
              onClick={() => history.push("/documents/pendingdocuments")}
              appearance="primary"
              style={{ marginRight: "0px", marginTop: "-6px", marginLeft: "10px", color: "black9", backgroundColor: "#4284F3" }}
            >
              {/* <Icon style={{ marginRight: '5px' }} icon='filter' />  */}
              Pending Documents
            </Button>
          </Col>
        </Row>

        <Row>
          <ListComponent
            page={filter.page}
            lists={list}
            filter={filter}
            history={history}
            // loading={loading}
            // totalCount={totalCount}
            onfilterChange={onfilterChange}
          />
        </Row>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  list: state.documents && state.documents.docs,
  totalCount: state.documents && state.documents.totalDocs,
  // loading : state.courses.loading
});

export default connect(mapStateToProps, { getStudentDocuments })(StudentDashboard);
