/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

// Set the alert Types
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// Set the auth Types
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOAD_USER = "LOAD_USER";
export const AUTH_FAIL = "AUTH_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";
export const REGENERATE_TOKEN = "REGENERATE_TOKEN";
export const TOKEN_EXTEND_OPEN = "TOKEN_EXTEND_OPEN";
export const TOKEN_EXTEND_CLOSE = "TOKEN_EXTEND_CLOSE";

// Set the orientation Types
export const ORIENTATION_SUCCESS = "ORIENRATION_SUCCESS";
export const ORIENTATION_FAILED = "ORIENTATION_FAILED";
export const SET_ORIENTATION = "SET_ORIENTATION";
export const GET_ORIENTATION = "GET_ORIENTATION";
export const GET_ORIENTATION_LIST = "ORIENRATION_SUCCESS";
export const ORIENTATION_LOADING = "ORIENTATION_LOADING";
export const ORIENTATION_COUNT = "ORIENTATION_COUNT";
export const ORIENTATION_REQUEST_LOADING = "ORIENTATION_REQUEST_LOADING";

// Set the retake types
export const SET_RETAKE = "SET_RETAKE";
export const RETAKE_ERROR = "RETAKE_ERROR";
export const GET_RETAKES = "GET_RETAKES";
export const RETAKE_LOADING = "RETAKE_LOADING";

// Set the schedule types
export const GET_SCHEDULES = "GET_SCHEDULES";
export const GET_GROUPS = "GET_GROUPS";
export const GET_ACADEMIC_PROFILE = "GET_ACADEMIC_PROFILE";
export const SET_GROUP = "SET_GROUP";
export const SET_GROUP_DENIED = "SET_GROUP_DENIED";
export const SET_SCHEDULE_LOADING = "SET_SCHEDULE_LOADING";

export const GET_ALL_STUDENTS = "GET_ALL_STUDENTS";
export const UPDATE_GRADUATION_INFO = "UPDATE_GRADUATION_INFO";
export const GET_GRADUATION_INFO = "GET_GRADUATION_INFO";
export const GET_EXPEDITED_GRADUATION_INFO = "GET_EXPEDITED_GRADUATION_INFO";

// Set the settings types
export const GET_SETTINGS = "GET_SETTINGS";

// Set the country ttoes
export const GET_COUNTRIES = "SET_COUNTIRIES";

// Get the class types
export const GET_CLASSES = "GET CLASSES";
export const GET_CLASSES_FILTERED_LIST = "GET_CLASSES_FILTERED_LIST";

// INTAKES
export const SET_INTAKES = "SET_INTAKES";
export const SET_PROGRAMS = "SET_PROGRAMS";
export const SET_DEPARTMENTS = "SET_DEPARTMENTS";
export const SET_FORM_INTAKES = "SET_FORM_INTAKES";

// Documents
export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const SET_DOCUMENTS_LOADING = "SET_DOCUMENTS_LOADING";
export const PENDING_DOCUMENTS = "PENDING_DOCUMENTS";
export const GET_SELECTED_DOCUMENT = "GET_SELECTED_DOCUMENT";
export const GET_STAFF_SELECTED_DOCUMENT = "GET_STAFF_SELECTED_DOCUMENT";
export const SUBMIT_ATTACHED_DOCUMENT = "SUBMIT_ATTACHED_DOCUMENT";

// Get the course types
export const GET_COURSES = "GET COURSES";
export const SET_COURSE_LOADING = "SET_COURSE_LOADING";

// Get the programs types
export const GET_PROGRAMS = "GET PROGGET_PROGRAMS";

//Immigration documents
export const GET_IMMIGRATION_DOCUMENT_LIST = "GET_IMMIGRATION_DOCUMENT_LIST";
export const GET_SELECTED_IMMIGRATION_DOCUMENTS = "GET_SELECTED_IMMIGRATION_DOCUMENTS";
export const GET_DOCUMENT_STATUS_LIST = "GET_DOCUMENT_STATUS_LIST";

export const CURRENT_INTAKE = "CURRENT_INTAKE";

//Instructor
export const GET_INSTRUCTOR_LIST = "GET_INSTRUCTOR_LIST";
export const GET_INSTRUCTOR_COUNT = "GET_INSTRUCTOR_COUNT";

//Set Forms
export const SET_FORMS = "SET_FORMS";

//Coop
export const GET_ORGANIZATION_OPTION = "GET_ORGANIZATION_OPTION";
export const GET_EMPLOYER_OPTION = "GET_EMPLOYER_OPTION";
export const GET_COOP_DETAIL = "GET_COOP_DETAIL";
export const GET_EMPLOYER_LOG = "GET_EMPLOYER_LOG";

//Helpdesk
export const GET_HELPDESK = "GET_HELPDESK";
export const SET_HELPDESK = "SET_HELPDESK";
export const UPDATE_HELPDESK = "UPDATE_HELPDESK";
export const HELPDESK_ERROR = "HELPDESK_ERROR";
export const HELPDESK_LOADING = "HELPDESK_LOADING";
export const CREATE_HELPDESK_SUCCESS = "CREATE_HELPDESK_SUCCESS";
export const FILTER_HELPDESK = "FILTER_HELPDESK"

//Safety Concern
export const GET_SAFETY_CONCERN = "GET_SAFETY_CONCERN";
export const SET_SAFETY_CONCERN = "SET_SAFETY_CONCERN";
export const UPDATE_SAFETY_CONCERN = "UPDATE_SAFETY_CONCERN";
export const SAFETY_CONCERN_ERROR = "SAFETY_CONCERN_ERROR";
export const SAFETY_CONCERN_LOADING = "SAFETY_CONCERN_LOADING";
export const CREATE_SAFETY_CONCERN_SUCCESS = "CREATE_SAFETY_CONCERN_SUCCESS";
