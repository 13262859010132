/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import { GET_COURSES, SET_COURSE_LOADING } from "../actions/types";

const initialState = {
  loading: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_COURSES:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case SET_COURSE_LOADING:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
};
