/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import { toaster } from "evergreen-ui/commonjs/toaster";
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Cascader, Modal, Row, Col, Grid, Button, Form, PanelGroup, Panel, Icon, FormGroup, ControlLabel, SelectPicker } from "rsuite";

import { getPendingDocuments, getSelectedDocument, deleteSubmittedDocument, setDocumentsLoading } from "../../../actions/documents";

import selector from "../../../assets/images/empty.svg";
import Empty from "../../layout/Empty";
import Loading from "../../layout/Loading/Loading";

import Dropzone from "./Dropzone";

const PendingDocument = ({
  myData,
  list,
  history,
  selectedDocuments,
  studentId,
  loading,
  getPendingDocuments,
  getSelectedDocument,
  deleteSubmittedDocument,
  setDocumentsLoading,
}) => {
  const documentsArray = [
    {
      label: "Fee Receipt",
      value: "Fee Receipt",
      role: "Orientation",
    },
    {
      label: "POC",
      value: "POC",
      role: "Retakes",
    },
  ];

  const [state, setState] = useState({
    document: null,
  });

  useEffect(() => {
    // get the student's pending documents in the redux state --
    //  make the selected document null , if the user switches the tab
    setDocumentsLoading(true);
    getSelectedDocument({ value: null, role: null });
    getPendingDocuments();
  }, []);

  const [visible, setVisible] = useState(false);

  const formHandler = (value) => {
    setState({ document: value });
  };

  const onSubmit = async () => {
    const res = await deleteSubmittedDocument(state.document);
    if (res.status === 200) {
      toaster.success("Document Successfully submitted");
      getPendingDocuments();
      // setVisible(true)
      window.location.reload(true);
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {list.length > 0 ? (
            <div>
              <Panel style={{ width: "70%", margin: "40px auto" }}>
                <h3 style={{ marginBottom: "30px", display: "inline-block", color: "#234361" }}> Submit Pending Documents </h3>

                <Form fluid>
                  <Panel style={{ marginBottom: "20px" }} shaded>
                    {/* this will contain the documents array from the  */}
                    <FormGroup>
                      <ControlLabel> Choose the document you want to submit from the options given below :</ControlLabel>
                      <SelectPicker
                        style={{ marginTop: "5px" }}
                        value={state.documentType}
                        disabled={selectedDocuments.length > 0 ? true : false}
                        onSelect={(value, data) => {
                          formHandler(data);
                          // supplying the whole selected object to fetch documents
                          getSelectedDocument(data);
                        }}
                        onChange={(value) => {
                          setVisible(false);
                        }}
                        onClean={() => setState({ document: null })}
                        data={list}
                        block
                      />
                    </FormGroup>

                    {state.document && selectedDocuments.length > 0 ? (
                      <p style={{ color: "red", marginTop: "-15px", marginBottom: "15px" }}>Delete / Submit the document to proceed ! </p>
                    ) : (
                      ""
                    )}

                    {state.document !== null ? (
                      <FormGroup>
                        <ControlLabel marginBottom={"5px"}> Please upload your selected Document here:</ControlLabel>
                        {/* <PanelGroup accordion bordered onSelect={()=> setVisible(true)}  > */}
                        <Panel
                          bordered
                          onSelect={() => {
                            !visible ? setVisible(true) : setVisible(false);
                          }}
                          expanded={true}
                          header={"Click here to submit the Document +"}
                          className={"immigrationDoc__header"}
                        >
                          {visible === true ? (
                            <Dropzone
                              styleName={"fileUpload"}
                              studentId={myData.studentId[0]}
                              fileName={state.document.value}
                              department={state.document.role}
                              data={state.document}
                              deleteUrl={"/documents"}
                              getUrl={"/documents"}
                              postUrl={"/documents"}
                            />
                          ) : (
                            ""
                          )}
                        </Panel>
                        {/* </PanelGroup> */}
                      </FormGroup>
                    ) : (
                      ""
                    )}
                  </Panel>
                </Form>

                {selectedDocuments.length > 0 ? (
                  <Button
                    onClick={onSubmit}
                    style={{ float: "right", backgroundColor: "#4285F4", color: "white", marginTop: "20px", marginBottom: "10px" }}
                    appearance="primary"
                  >
                    {" "}
                    Save
                  </Button>
                ) : (
                  " "
                )}
              </Panel>
            </div>
          ) : (
            <div>
              <Empty
                img="https://storage.googleapis.com/glaze-ecom.appspot.com/images/wzPbmUrXM/thumbs/watermark.png"
                title="No Pending Documents"
                message="There are no Documents pending currently"
                buttonValue="Back"
                link="/documents/dashboard"
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

const mapPropsToState = (store) => {
  return {
    studentId: store.auth.user.studentId[0] || { permission: -1 },
    myData: store.auth.user,
    list: store.documents.pendingList,
    selectedDocuments: store.documents.selectedDocuments,
    loading: store.documents.loading,
  };
};

export default connect(mapPropsToState, { getPendingDocuments, getSelectedDocument, deleteSubmittedDocument, setDocumentsLoading })(
  PendingDocument,
);
