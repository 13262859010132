/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import React, { Fragment, useState, useCallback, useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { connect } from "react-redux";

import { getSelectedDocument } from "../../../actions/documents";

// Get the UI components
import { Pane, Dialog, Button } from "evergreen-ui";
import mainAxios from "../../../axios/Axios";
import downloadFile from "../../../utils/downloadFile"

const StaffDropzone = ({
  type,
  department,
  data,
  studentId,
  styleName,
  deleteUrl,
  getUrl,
  postUrl,
  getSelectedDocument,
  getStaffSelectedDocument,
}) => {
  // Initial formdata
  const [loading, setLoading] = useState(false);
  // setting the confirmation dialog state
  const [isShown, setShown] = useState({ show: false, id: "" });
  // the container for storing documents locally getting from an api
  const [container, setContainer] = useState([]);

  // intiially get all the docs which the student has submitted
  useEffect(() => {
    getInitialDocuments(studentId, type, department);
  }, [type]);

  // remove a document ---- setting status to false
  function handleRemove(documentId) {
    mainAxios.delete(`/documents/delete/${documentId}`).then((res) => {
      setContainer(res.data);
      getInitialDocuments(studentId, type, department);
      getStaffSelectedDocument({ type, studentId, department });
      // get the selected document's list again
      // getSelectedDocument(data)
    });
  }

  // get intial docs of a student
  async function getInitialDocuments(studentId, type, department) {
    console.log("request gone...");
    const data = await mainAxios.get(`/documents/${studentId}/${type}/${department}`).then((res) => {
      setContainer(res.data);
    });
  }

  // function for uploading docs
  async function uploadDocs(file) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", type);
    formData.append("studentId", studentId);
    formData.append("department", department);

    await mainAxios.post(`/documents/staff`, formData);

    getStaffSelectedDocument({ type, studentId, department });
    // get the selected document's list again
    //   getSelectedDocument(data)
  }

  // on dropping files
  const onDrop = useCallback(async (files) => {
    // Do something with the files
    // Append the data
    setLoading(true);
    const file = files[0];

    // upload the documents and again get the initail files and set them --
    await uploadDocs(file);
    getInitialDocuments(studentId, type, department);
    setLoading(false);
  }, []);

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png, image/jpg, application/zip, application/pdf",
    maxSize: 10000000,
  });

  //  panel for checking if the student really want to delete a document
  //  alert to a student to confirm if he wants to delete a document
  if (isShown.show) {
    return (
      <Pane>
        <Dialog
          isShown={isShown.show}
          style={{ zIndex: "99999" }}
          title="Alert"
          intent="danger"
          onCloseComplete={() => setShown({ show: false })}
          onConfirm={() => {
            setShown({ show: false });
            handleRemove(isShown.id);
          }}
          confirmLabel={"Delete"}
        >
          Do you want to delete the document ?
        </Dialog>
      </Pane>
    );
  } else
    return (
      <Fragment>
        <div {...getRootProps()} className={styleName}>
          <input {...getInputProps()} />
          <div className="uploadIcon">
            {loading ? (
              <div>
                <img src="/img/gif/spinner.gif" />
                <p>Uploading ...</p>
              </div>
            ) : (
              <div>
                <img src="/img/icons/upload.png" />
                <p>Drag 'n' drop some files here, or click to select files</p>
              </div>
            )}
          </div>
        </div>
        <ul className="list-group mt-2">
          {container &&
            container.length > 0 &&
            container.map((acceptedFile) => (
              <>
                <a   onClick={() => {
                                downloadFile(acceptedFile.fileInfo || "");
                              }}>
                  <li className="list-group-item list-group-item-success registration__dropzone">
                    <img src={`/img/files/${acceptedFile.fileInfo.ext}.png`} />
                    <p>
                      {acceptedFile.fileInfo.name.length > 10
                        ? acceptedFile.fileInfo.name.substr(0, 10 - 1) + `...${acceptedFile.fileInfo.ext}`
                        : acceptedFile.fileInfo.name}
                    </p>
                  </li>
                </a>
                <button
                  className="registration__removeDoc"
                  onClick={() => {
                    setShown({ show: true, id: acceptedFile._id });
                  }}
                >
                  x
                </button>
              </>
            ))}
        </ul>
      </Fragment>
    );
};

const mapStateToProps = (state) => ({
  docs: state.orientation.docs,
  selectedDocuments: state.documents.selectedDocuments,
});

export default connect(mapStateToProps, { getSelectedDocument })(StaffDropzone);
