/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import { GET_CLASSES, GET_CLASSES_FILTERED_LIST } from "../actions/types";

const initialState = {
  loading: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CLASSES:
      return {
        ...state,
        ...payload.classes,
      };
    case GET_CLASSES_FILTERED_LIST:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
