/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import jwtDecode from "jwt-decode";

import { Modal, Button } from "rsuite";

import { tokenExtendClose, tokenExtendOpen, regenerateToken } from "../../actions/auth";

const TokenExtendModal = ({ tokenExtend, tokenExtendOpen, tokenExtendClose, regenerateToken }) => {
  const [state, setState] = useState({
    user: {},
  });

  const [counter, setCounter] = useState(15);

  const token = localStorage.getItem("token");

  useEffect(() => {
    let decodedToken = token ? jwtDecode(token) : "";

    setState({ user: decodedToken.user });
  }, [token]);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  return (
    <div>
      <div className="modal-container">
        <Modal show={tokenExtend} onEntering={() => setCounter(15)} className="responsive-container">
          <Modal.Header closeButton={false} marginBottom={0}>
            <Modal.Title>WARNING ⚠️</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ marginBottom: "10px", marginTop: "11x", paddingTop: "10px" }}>
            <p style={{ fontWeight: "bold", display: "inline", paddingTop: "10px" }}>
              {`Your session is expiring in ${counter}s. Would you like to increase the 
               session`}{" "}
              ?
            </p>

            {/* <span style={style}>
              <Circle percent={count} strokeColor="#ffc107" />
            </span> */}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                regenerateToken(state, token);
              }}
              color="yellow"
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  tokenExtend: state.auth.tokenExtend,
});

export default connect(mapStateToProps, { tokenExtendOpen, tokenExtendClose, regenerateToken })(TokenExtendModal);
