/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import { GET_INSTRUCTOR_LIST,GET_INSTRUCTOR_COUNT } from "../actions/types";

const initialState = {
  instructor: {},
  totalCount: 0,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_INSTRUCTOR_LIST:
      return { ...state, instructor: payload};
    case GET_INSTRUCTOR_COUNT:
      return { ...state, totalCount: payload };
    default:
      return state;
  }
};
