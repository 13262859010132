/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import "./Responsive.css";
import config from "./config.js";
import FreshChat from "react-freshchat";
import PrivateRoute from "./routing/PrivateRoute";
// Redux
import { Provider } from "react-redux";
import store from "./store.js";
import { loadUser } from "./actions/auth";
import Alerts from "./components/layout/Alerts.js";
import PrivateView from "./components/HOC/PrivateView/PrivateView";
import NavBar from "./components/layout/NavBar";
import MobileNavBar from "./components/layout/MobileNavBar";
import Loading from "./components/layout/Loading/Loading";

import TokenExtend from "./components/layout/tokenExtendModal";

// documents route;
import StudentDocumentList from "./components/pages/documents/studentListContainer";
import StudentDashboard from "./components/pages/documents/studentDashboard";
import SubmittedDocumentsList from "./components/pages/documents/submittedDocumentsList";
import PendingDocuments from "./components/pages/documents/pendingDocuments";



const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    );
    // try to import the component
    componentImport().then((component) => {
      window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
      resolve(component);
    }).catch((error) => {
      if (!hasRefreshed) { // not been refreshed yet
        window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
        return window.location.reload(); // refresh the page
      }
      reject(error); // Default error behaviour as already tried refresh
    });
  });
};




// Import the components as pages
const Login = React.lazy(() => lazyRetry(() => import("./components/auth/Login.js")));
const Register = React.lazy(() => import("./components/auth/Register.js"));

// const = React.lazy(() => import()) the orientation
const Orientation = React.lazy(() => import("./components/pages/orientation/route.js"));
const DocumentLink = React.lazy(() => import("./components/pages/orientation/profileImage"));

// Profile components
const ProfileRoutes = React.lazy(() => import("./components/pages/profiles/route.js"));

//Alumini List
const AluminiList = React.lazy(() => import("./components/pages/alumini/list"));

// Immigration Form
const ImmigrationForm = React.lazy(() => import('./components/pages/ImmigrationDocuments/ImmigrationForms.js'));
const ImmigrationList = React.lazy(() => import('./components/pages/ImmigrationDocuments/ImmigrationList'));
const ImmigrationProfileView = React.lazy(() => import('./components/pages/ImmigrationDocuments/ImmigrationProfileView'));
const ImmigrationProfileEdit = React.lazy(() => import('./components/pages/ImmigrationDocuments/ImmigrationProfileEdit'));
// Graduation form
// All steps here ---
const GraduationForm = React.lazy(() => import("./components/pages/graduationForm/Step1.js"));
const GraduationList = React.lazy(() => import("./components/pages/graduationForm/list"));
const GraduationView = React.lazy(() => import("./components/pages/graduationForm/view"));

// Expedited Graduation Form
const ExpeditedGraduationForm = React.lazy(() => import("./components/pages/expeditedGraduationForm/Form.js"));
const ExpeditedGraduationList = React.lazy(() => import("./components/pages/expeditedGraduationForm/list"));
const ExpeditedGraduationView = React.lazy(() => import("./components/pages/expeditedGraduationForm/view"));

// Groups components
const GroupRoutes = React.lazy(() => import("./components/pages/groups/route.js"));
const GroupSelectionStudentList = React.lazy(() => import("./components/pages/groups/groupSelectionStudentList"));
const GroupSelectionDetails = React.lazy(() => import("./components/pages/groups/groupSelectionDetails"));

// const = React.lazy(() => import()) the orientation
const Schedules = React.lazy(() => import("./components/pages/schedules/Route.js"));

// Dashboard
const Dashboard = React.lazy(() => import("./components/pages/dashboard/Dashboard.js"));
const EmptyPage = React.lazy(() => import("./components/pages/dashboard/EmptyPage.js"));

// Retakes
const RetakeRoute = React.lazy(() => import("./components/pages/retakes/RetakeRoute"));
const ApplyRetakes = React.lazy(() => import("./components/pages/retakes/ApplyRetakes.js")); // Retake form
const RetakeSchedule = React.lazy(() => import("./components/pages/retakes/RetakeSchedule")); // Retake schedule pickup form
const Retakes = React.lazy(() => import("./components/pages/retakes/Retakes")); // Get the list of retakes
const viewRetake = React.lazy(() => import("./components/pages/retakes/viewRetake")); // View the current retake

const CreateStudents = React.lazy(() => import("./components/pages/dashboard/CreateStudents"));
const CreateProfiles = React.lazy(() => import("./components/pages/profiles/createProfiles"));
const UsersSettings = React.lazy(() => import("./components/pages/users/UsersSettings/UsersSettings"));

// Courses lists
const Courses = React.lazy(() => import("./components/pages/courses/list.js"));
// Programs lists
const Programs = React.lazy(() => import("./components/pages/programs/list.js"));
// Classes lists
const Classes = React.lazy(() => import("./components/pages/classes/list.js"));
// Intakes lists
const Intakes = React.lazy(() => import("./components/pages/intakes/list"));

//Student collectionlist
const StudentList = React.lazy(() => import("./components/pages/students/list"));
const StudentView = React.lazy(() => import("./components/pages/students/view"));
const StudentEditPage = React.lazy(() => import("./components/pages/students/edit"));

//instructor list
const Instructor = React.lazy(() => import("./components/pages/instructor/list"));

//set program forms
const SetForms = React.lazy(() => import("./components/pages/forms/setForms"));

// Error page
const PageNotFound = React.lazy(() => import("./components/layout/PageNotFound.js"));

// !Not active currenty -- 12/29/2020
const QuarantineRoutes = React.lazy(() => import("./services/QuarantinePackage/Containers/Routes"));

// Mass emails list
const MassEmailsList = React.lazy(() => import("./components/pages/massEmails/list"));
const MassEmailsBatchList = React.lazy(() => import("./components/pages/massEmails/batchList"));
const ViewProfile = React.lazy(() => import("./components/pages/massEmails/view"));

// convocation component
const AllConvocationLetters = React.lazy(() => import("./components/pages/convocation/allConvocationLetters"))
const StudentConvocationLetters = React.lazy(() => import("./components/pages/convocation/studentLetters"))

//helpdesk
const HelpDeskRoute = React.lazy(() => import("./components/pages/helpdesk/HelpDeskRoute"));
const StudentHelpDesk = React.lazy(() => import("./components/pages/helpdesk/StudentHelpDesk"));
const CreateHelp = React.lazy(() => import("./components/pages/helpdesk/createHelp"));
const ViewHelp = React.lazy(() => import("./components/pages/helpdesk/viewHelp"));

// safetyConcern
const SafetyConcernRoute = React.lazy(() => import("./components/pages/safetyConcern/SafetyConernRoute"));
const CreateSafety = React.lazy(() => import("./components/pages/safetyConcern/CreateSafety"));
const ViewSafety = React.lazy(() => import("./components/pages/safetyConcern/ViewSafety"));

// Coop department page
const Coop = React.lazy(() => import("./components/pages/coop/coop"));
const ViewCoop = React.lazy(() => import("./components/pages/coop/viewCoop"));




// Non blocking import for CSS
(async function () {
  await import("rsuite/dist/styles/rsuite-default.css");
})();

const App = () => {
  // Check the user authentication
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 768;
  useEffect(() => {
    store.dispatch(loadUser());
    // show the pop up from here , if the token is about to expire
    // and user wants to increase their session
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);
  return (
    <Provider store={store}>
      {/* <FreshChat token={config.freshChat} /> */}
      <TokenExtend />

      <React.Suspense fallback={Loading}>
        <Router>
          <PrivateView accessService="privatePublicAll">
            {" "}
            {width && breakpoint && width > breakpoint
              ? <NavBar />
              : <MobileNavBar />
            }
            {" "}
          </PrivateView>
          <Alerts />
          <Switch>
            <PrivateRoute exact path="/Empty" component={EmptyPage} />
            <PrivateRoute exact path="/" component={Dashboard} />
            <PrivateRoute exact path="/retake" component={RetakeRoute} />



            {/* Auth Routes  */}
            <Route path="/login" component={Login} />
            <Route path="/stafflogin" component={Login} />
            <Route path="/register" component={Register} />

            {/* Registration Routes */}
            <Route exact accessService={"allAdminsNdSuper"} path="/orientation/studentImage/:student/:type" component={DocumentLink} />
            <Route path="/orientation" component={Orientation} />

            <Route path="/profile" accessService={"massEmailsNdOrientation"} component={ProfileRoutes} />

            {/* Aluminis info  */}
            <PrivateRoute path="/aluminis" accessService={'approvedStudents'} component={AluminiList} />

            {/* immigration doc form */}
            <PrivateRoute path="/immigration" accessService={'approvedStudents'} component={ImmigrationForm} />
            <PrivateRoute path="/documents/immigrationlist" accessService={'allAdminsNdSuper'} component={ImmigrationList} />
            <PrivateRoute path="/documents/immigration/:id" accessService={'allAdminsNdSuper'} component={ImmigrationProfileView} />

            <PrivateRoute path="/documents/immigrationEdit/:id" accessService={'allAdminsNdSuper'} component={ImmigrationProfileEdit} />

            {/* Graduation certificate form */}
            <PrivateRoute path="/documents/graduation" accessService={"approvedStudents"} component={GraduationForm} />
            <PrivateRoute path="/graduation/list" accessService={"graduation"} component={GraduationList} />
            <PrivateRoute path="/graduation/view/:id" accessService={"graduation"} component={GraduationView} />

            {/* Expedited Graduation form -- graduation dept access */}
            <PrivateRoute path="/expedited/form" accessService={"approvedStudents"} component={ExpeditedGraduationForm} />
            <PrivateRoute path="/expedited/list" accessService={"graduation"} component={ExpeditedGraduationList} />
            <PrivateRoute path="/expedited/view/:id" accessService={"graduation"} component={ExpeditedGraduationView} />

            {/* Quarantine Routes */}
            {/* <PrivateRoute path="/quarantine" accessService={'privatePublic'} component={Quarantine} /> */}

            {/* Group routes */}
            <PrivateRoute path="/groups" component={GroupRoutes} />
            <PrivateRoute path="/selected/groups" component={GroupSelectionStudentList} />
            <PrivateRoute path="/selected/student/:id" component={GroupSelectionDetails} />

            {/* Schedules routes */}
            <PrivateRoute path="/schedules" accessService={"approvedStudents"} component={Schedules} />

            {/* Create Student */}
            <PrivateRoute path="/createStudents" accessService={"orientation"} exact component={CreateStudents} />

            {/* Create Profile */}
            <PrivateRoute path="/uploadprofiles" accessService={"orientation"} exact component={CreateProfiles} />

            {/* Retakes routes */}
            <PrivateRoute exact path="/retake/apply" accessService={"approvedStudents"} component={ApplyRetakes} />
            <PrivateRoute path="/retake/schedules/:id" accessService={"retakesNdApprovedStd"} component={RetakeSchedule} />
            {/* change the permission */}

            <PrivateRoute path="/retake/list/:page" accessService={"retakesNdApprovedStd"} component={Retakes} />
            <PrivateRoute path="/retake/list" accessService={"retakesNdApprovedStd"} component={Retakes} />

            <PrivateRoute path="/retake/:id" accessService={"retakesNdApprovedStd"} component={viewRetake} />

            <PrivateRoute path="/accessManager" accessService={"superOnly"} component={Orientation} />
            <PrivateRoute path="/createUser" accessService={"superOnly"} component={Orientation} />

            {/* ⛔️ Removing documents feature until needed   */}
            <PrivateRoute accessService="allAdminsNdSuper" path="/documents/studentlist" component={StudentDocumentList} />
            <PrivateRoute accessService="approvedStudents" path="/documents/list" component={SubmittedDocumentsList} />
            <PrivateRoute accessService="approvedStudents" path="/documents/dashboard" component={StudentDashboard} />
            <PrivateRoute accessService="approvedStudents" path="/documents/pendingdocuments" component={PendingDocuments} />

            {/* Routes for all students collection */}
            <PrivateRoute path="/students/list" accessService={"orientation"} component={StudentList} />
            <PrivateRoute accessService={"orientation"} path="/students/view/:id" component={StudentView} />
            <PrivateRoute accessService={"orientation"} path="/students/edit/:id" component={StudentEditPage} />

            <PrivateRoute path="/massemails/list" accessService={"allAdminsNdSuper"} component={MassEmailsList} />
            <PrivateRoute exact accessService={"allAdminsNdSuper"} path="/massemails/view/:id" component={ViewProfile} />
            <PrivateRoute path="/massemails/batchlist" accessService={"allAdminsNdSuper"} component={MassEmailsBatchList} />

            <PrivateRoute path="/usersSettings" accessService={"superOnly"} component={UsersSettings} />

            {/* <Route path="/quarantine" component={QuarantineRoutes} /> */}

            {/* Mass emails list */}
            <PrivateRoute path="/courses" accessService={"superOnly"} component={Courses} />

            {/* Academic routes */}
            <PrivateRoute path="/courses" accessService={"superOnly"} component={Courses} />
            <PrivateRoute path="/programs" accessService={"superOnly"} component={Programs} />
            <PrivateRoute path="/classes" accessService={"superOnly"} component={Classes} />

            {/* Intakes  */}
            <PrivateRoute path="/intakes" accessService={"superOnly"} component={Intakes} />
            {/* Instructor*/}
            <PrivateRoute path="/instructor" accessService={"superOnly"} component={Instructor} />
            {/*Set Program Forms*/}
            <PrivateRoute path="/setForms" accessService={"allAdminsNdSuper"} component={SetForms} />
            {/*Coop Page, the url matches one by one so the more detailed url should be on the top*/}
            <PrivateRoute path="/coop/view/:type/:id" accessService={"coop"} component={ViewCoop} />
            <PrivateRoute path="/coop" accessService={"coop"} component={Coop} />

            {/* Convocation page route/ */}
            <PrivateRoute path="/convocation" accessService={"superOnly"} component={AllConvocationLetters} />
            <PrivateRoute path="/status/convocation" accessService={"approvedAndGraduatedStudents"} component={StudentConvocationLetters} />

            {/* Help Desk route */}
            <PrivateRoute exact path="/helpdesk" component={HelpDeskRoute} />
            <PrivateRoute path="/helpdesk/student" accessService={"approvedStudents"} component={StudentHelpDesk} />
            <PrivateRoute path="/helpdesk/create" accessService={"approvedStudents"} component={CreateHelp} />
            <PrivateRoute path="/helpdesk/:id" accessService={"allAdminsNdSuper"} component={ViewHelp} />

            {/* Safety Concern route  */}
            <PrivateRoute exact path="/safetyconcern" accessService={"allAdminsNdSuper"} component={SafetyConcernRoute} />
            <PrivateRoute path="/safetyconcern/createsafety" accessService={"privatePublicAll"} component={CreateSafety} />
            <PrivateRoute path="/safetyconcern/:id" accessService={"allAdminsNdSuper"} component={ViewSafety} />


            {/* All random pages */}
            <Route path="/*" component={() => <PageNotFound />} />
          </Switch>
        </Router>
      </React.Suspense>
    </Provider>
  );
};

export default App;
