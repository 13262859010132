import axios from 'axios';
import store from '../store';
import config from "../config";
import { toaster } from 'evergreen-ui';
 
const defaultAxios = axios.create({
    baseURL: config.server,
    timeout: 30000
});
 
defaultAxios.interceptors.request.use(
    async config => {
 
        // const token = localStorage.getItem('token');
        config.headers["x-auth-token"] = store.getState().auth.token;
        return config;
    },
    error => {
        Promise.reject(error)
    }
);
 
defaultAxios.interceptors.response.use((response) => {
    return response;
 
}, (error) => {
    if(!error.response){
        console.log(error);
    }
  else{
    switch (error.response.status || 500) {
        case 401:
            // toaster.danger( 'Permission Denied')
            error.response.data  && error.response.data.errors.map(item =>{
                toaster.danger(item.msg);
            })
            break;
        case 422:
//            toaster.danger('Validation Error');
            error.response.data  && error.response.data.errors.map(item =>{
                toaster.danger(item.msg);
            })
        break;    
        case 400:
            // toaster.danger('Something Wrong');
            if(error.response.data){
                error.response.data  && error.response.data.errors.map(item =>{
                    toaster.danger(item.msg);
                })
            }
            else{
              toaster.danger('Something Wrong');
            }
        break;  
        case 404:
            if(error.response){
                error.response.data  && error.response.data.errors.map(item =>{
                    toaster.danger(item.msg);
                })
            }
            else{
              toaster.danger('Something Wrong');
            }
        break;  
        case 500:
            console.log(error.response)
            if(error.response){
                error.response  && error.response.data.errors.map(item =>{
                    toaster.danger(item.msg);
                })
            }
            else{
                toaster.danger('Internal Server Error, Please contact support in case of inconvenience');
            }
        break;    
        default:
            break;
    }
}
// }
    return Promise.reject(error.message);
});
 
export default defaultAxios;
