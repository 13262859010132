/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import ErrorBoundary from './components/HOC/ErrorBoundry';

ReactDOM.render(<App />, document.getElementById("root"));
