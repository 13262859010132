/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import { UPDATE_GRADUATION_INFO, GET_GRADUATION_INFO , GET_EXPEDITED_GRADUATION_INFO } from "../actions/types";

const initialState = {
  loading: false,
  graduation: {},
  expeditedGradutaion : {}
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_GRADUATION_INFO:
      return { ...state, graduation: payload, loading: false };
    case GET_EXPEDITED_GRADUATION_INFO:
       return {...state , expeditedGradutaion : payload , loading  : false}
    default:
      return state;
  }
};
