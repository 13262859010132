/*This is a file that contains setting for outlook graph api client and 
microsoft authentication library for browser (msal)
This page should be gitignored 
*/
import "isomorphic-fetch"; // or import the fetch polyfill you installed
import { Client } from "@microsoft/microsoft-graph-client";
import { PublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";
/*
const msalConfig = {
    auth: {
        clientId: '9ccc087c-300e-4b12-8e44-119575ee777b',
        authority: 'https://login.microsoftonline.com/7ec8b1bc-bc4a-4a68-bcae-c77b90bf63ea',
        redirectUri: 'https://services.queenscollege.ca/coop'
    }
};*/

const msalConfig = {
    auth: {
        clientId: 'f0095ae0-7b8c-4cc5-9f17-8b9b7310578c',
        authority: 'https://login.microsoftonline.com/7ec8b1bc-bc4a-4a68-bcae-c77b90bf63ea',
        redirectUri: 'https://services.queenscollege.ca/login',
        postLogoutRedirectUri: 'https://services.queenscollege.ca/login'
    }
};

export const msalRequest = { scopes: [] };

export const ensureScope = (scope) => {  // function to specify scope 
    if (!msalRequest.scopes.some((s) => s.toLowerCase() === scope.toLowerCase())) {
        msalRequest.scopes.push(scope);
    }
}

//Initialize MSAL client
export const msalClient = new PublicClientApplication(msalConfig);

const getToken = async() => {   // check if there is a token for login and return the token
        let account = sessionStorage.getItem('msalAccount');
        if (!account) {
            throw new Error(
                'User info cleared from session. Please sign out and sign in again.');
        }
        try {
            // First, attempt to get the token silently
            const silentRequest = {
                scopes: msalRequest.scopes,
                account: msalClient.getAccountByUsername(account)
            };

            const silentResult = await msalClient.acquireTokenSilent(silentRequest);
            return silentResult.accessToken;
        } catch (silentError) {
            // If silent requests fails with InteractionRequiredAuthError,
            // attempt to get the token interactively
            if (silentError instanceof InteractionRequiredAuthError) {
                const interactiveResult = await msalClient.acquireTokenPopup(msalRequest);
                return interactiveResult.accessToken;
            } else {
                throw silentError;
            }
        }
    }
    
const authProvider = {
    getAccessToken: async () => {
        return await getToken();
    }
};
export const graphClient = Client.initWithMiddleware({ authProvider });


