/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

const isRoutePermitted = (user, accessLevel = [], departmentsAccess = {}) => {
  try {
    let isPermitted = false;

    if (accessLevel.includes(user.permission)) {
      if (user.permission === 2) {
        const { departments } = user;

        for (let department of departments) {
          const currentDep = departmentsAccess["$all"] || departmentsAccess[department.name];

          if (!!currentDep) {
            isPermitted = currentDep.has("$all") || currentDep.has(department.role);
            if (isPermitted) break;
          }
        }
      } else {
        isPermitted = true;
      }
    }

    return isPermitted;
  } catch (err) {
    console.log(err);
    return false;
  }
};
const mapToInputPicker = (obj = [], labelName, valueName, key) => {
  try {
    const newData = Object.values(obj).map((item) => {
      return {
        label: item[labelName],
        value: item[valueName],
        key: item[key],
      };
    });

    return newData;
  } catch (err) {
    console.log(err);
    alert("Error Occurred while mapping the countries.");
  }
};
export { isRoutePermitted , mapToInputPicker };
