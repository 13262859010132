/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import { GET_GROUPS, GET_ACADEMIC_PROFILE, SET_GROUP, SET_SCHEDULE_LOADING, SET_GROUP_DENIED } from "../actions/types.js";

const initialState = {
  groups: [],
  schedules: {},
  loading: true,
  academic: {},
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_GROUPS:
      return {
        ...state,
        groups: payload,
        loading: false,

      };
    case GET_ACADEMIC_PROFILE:
      return {
        ...state,
        loading: false,
        academic: payload,

      };
    case SET_GROUP:
      return {
        ...state,
        groups: payload,
        loading: false,
      };

    case SET_SCHEDULE_LOADING:
      return {
        ...state,
        loading: payload,
      };
    default:
      return {
        ...state,
        loading: false,
      };
  }
};
