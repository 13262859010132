/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import React from "react";
import styles from "./Loading.module.css";

class Loading extends React.PureComponent {
  render() {
    return (
      <div className={styles.PageLoading}>
        <div className={styles.threeBalls}>
          <div className={styles.ball + " " + styles.ball1}></div>
          <div className={styles.ball + " " + styles.ball2}></div>
          <div className={styles.ball + " " + styles.ball3}></div>
        </div>
      </div>
    );
  }
}

export default Loading;
