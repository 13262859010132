/*
 *  Copyright (C) 2016-2024 Queen's College of Business, Technology & Public Safety
 *
 *  This program is proprietary and confidential information of Queen's College of Business, Technology & Public Safety.
 *  Unauthorized use, copying or distribution of this program, or any portion of it, is strictly prohibited.
 *
 */

import React from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PrivateView from "../HOC/PrivateView/PrivateView";

import { Navbar, Nav, Panel, Avatar, Dropdown } from "rsuite";

// Redux
import { logout } from "../../actions/auth";

// Import the UI components
import { Pane, Card, Tab, Popover, Menu, Position, Text } from "evergreen-ui";

const NavBar = ({ logout, user, navigationData, history, props }) => {
  // get the user
  let name = "";

  if (user) name = user.firstName[0] + " " + user.lastName[0];

  return (
    <>
      <Panel style={{ overflow: "visible" }}>
        <div style={{ margin: "0 5%", marginLeft: "7%", marginRight: "4%" }}>
          <Navbar appearance="subtle">
            <Navbar.Header className="logo-img">
              <a href="/" className="navbar-brand logo">
                <img width="140" src="/img/logo.png" />
              </a>
            </Navbar.Header>
            <Navbar.Body>
              <Nav onSelect={() => {}} activeKey={0} pullRight className="flex-nav">
                {navigationData.map((navItem, index) => {
                  return (
                    <PrivateView key={navItem.key} accessService={navItem.permission}>
                      {!!navItem.dropdown ? (
                        <Dropdown trigger={['click', 'hover']} title={navItem.label}>
                          {navItem.dropdown.map((item) => (
                            <PrivateView key={item.key} accessService={item.permission}>
                              <Dropdown.Item style={{ paddingTop: "0px", width: "120px" }} eventKey="4" href={item.path}>
                                {item.label}
                              </Dropdown.Item>
                            </PrivateView>
                          ))}
                        </Dropdown>
                      ) : (
                        <Link from="/" to={navItem.path}>
                          {" "}
                          <Nav.Item eventKey={index}>{navItem.label}</Nav.Item>
                        </Link>
                      )}
                    </PrivateView>
                  );
                })}
                <Dropdown
                  trigger={['click', 'hover']}
                  placement="bottomEnd"
                  title={
                    <div style={{ marginTop: "-5px" }}>
                      {" "}
                      <span style={{ marginRight: "5px" }}>{name}</span>{" "}
                      <Avatar size="sm" circle>
                        {name[0]}
                      </Avatar>
                    </div>
                  }
                >
                  {user.permission === 1 ? (
                    <Dropdown.Item style={{ width: "120px" }} onSelect={() => history.push(`/profile/edit/${user._id[0]}`)}>
                      {" "}
                      Profile
                    </Dropdown.Item>
                  ) : (
                    ""
                  )}
                  <Dropdown.Item style={{ width: "120px" }} onSelect={() => logout(user)} eventKey="4">
                    Logout
                  </Dropdown.Item>
                </Dropdown>
              </Nav>
            </Navbar.Body>
          </Navbar>
        </div>
      </Panel>
    </>
  );
};

NavBar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProp = (state) => ({
  user: state.auth.user,
  navigationData: state.settings.navigationData,
});

export default withRouter(connect(mapStateToProp, { logout })(NavBar));
